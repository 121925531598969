import './App.css';
import NavBar from "./Components/NavBar/NavBar";
import Home from "./Components/Home/Home"

function App() {
  return (
      <>
        <NavBar/>
          <Home/>
      </>
  );
}

export default App;
